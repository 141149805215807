import { BorderBottom } from "@mui/icons-material";
import React, { useState } from "react";
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import logo from '../assi_logo.png';

function Header() {

 const [currentTime,SetCurrentDate] = useState(new Date().toLocaleString());

 function Setter() {
    SetCurrentDate(new Date().toLocaleString());
 }  

setInterval(Setter, 1000);




   return (
      <div style={{ width: '100%', height: '8vh', background: 'darkred', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 10px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={logo} style={{ width: '50px' }} />
        </div>
        
        <label style={{ color: 'white', fontWeight: '600', fontSize: '20px', fontFamily: 'Nunito', marginRight: "20px" }}>{currentTime}</label>
      </div>
    );

}

export default Header;