import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import ListOfRates from './Table';
import '../App.css';
import {MANIAL_RATE_ENDPOINT} from './API/APIEndpoints';



export default function Manila(props) {
    
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [data, setData] = useState([]);
  const maxSteps = data.length;



    useEffect(() => {
        const fetchData = async () => {
        try {
            const response = await fetch(MANIAL_RATE_ENDPOINT());
            const result = await response.json();
            setData(result.message);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
        };

        fetchData();
    }, [])


  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      // Check if the current step is the last step
      if (prevActiveStep === maxSteps - 1) {
        // If it is, reset to the first step
        return 0;
      } else {
        // Otherwise, go to the next step
        return prevActiveStep + 1;
      }
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep - 1 + maxSteps) % maxSteps);
  };

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      handleNext();
    }, 10000);

    return () => {
      // Clear the interval when the component is unmounted
      clearInterval(intervalId);
    };
  }, [activeStep]);


  const cities = data[activeStep]?.cities || [];


  return (
    <Box>
      <Paper
        square
        elevation={0}
        sx={{
          marginTop: '3px',
          display: 'flex',
          alignItems: 'center',
          height: 30,
          pl: 2,
          bgcolor: 'background.default',
        }}
      >
       <Typography><span  style={{fontWeight:'800',fontFamily:'Nunito'}} >{ props.name } - {data[activeStep]?.label}</span></Typography>
      </Paper>
      <Box sx={{  width: '100%', flexGrow: 1, }}>
        <table className='table_rates'>
            <thead>
                <tr>
                    <th>Name</th>
                    <th style={{width:"25%"}}>Rate</th>
                </tr>
            </thead>
            <tbody>
                 {cities.map((city) => (
                     <ListOfRates key={city.city_id} id={city.city_id} name={city.city_name}  rate={city.rate} />
                ))}
            </tbody>
        </table>
      </Box>
      <MobileStepper
        style={{height:"20px"}}
        variant="text"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
  );
}