const Base_URL = "https://api.assibooking.com/booking/";

const MANIAL_RATE_ENDPOINT = () =>{
      return Base_URL+'provinces/1';
}


const PROVINCIAL_RATE_ENDPOINT = () =>{
      return Base_URL+'provinces/2';
}


export { MANIAL_RATE_ENDPOINT,PROVINCIAL_RATE_ENDPOINT }