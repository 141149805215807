import React, { useEffect } from 'react';
import './App.css';
import Header from './Components/Header';
import Grid from '@mui/material/Grid';
import Manila from './Components/Manila';
import Provincial from './Components/Provincial';

function App() {



    return (
      <div>
      <Header />
      <Grid container spacing={.4} style={{background:'#131212'}}>
       <Grid item xs={12} sm={12} lg={12}>
          <Manila name="MANILA RATE"/>
        </Grid>
        <Grid item xs={12} sm={12}  lg={12}>
          <Provincial name="PROVINCIAL RATE" />
        </Grid>
      </Grid>
      </div>
    );

}

export default App;
