import * as React from 'react';
import '../App.css';


export default function ListOfRates(props) {
  return (
    <tr>
     <td className='td_table'>{props.name}</td>
     <td className='td_table'>₱ {props.rate.toLocaleString()}</td>
    </tr>
  );
}